import "./styles.css";

import { Button, Grid, TextField } from "@mui/material";
import { LottieScrollSection } from "react-lottie-tools";
import React, {useState} from "react";

import animationData from "./static/data.json";

import client1Data from "./static/data.json";
import client2Data from "./static/data.json";
import client3Data from "./static/data.json";

const clientsData = [client1Data, client2Data, client3Data];
const script = {
  welcome: {
    mapping: {
      companyName: '[Client Name]'
    }
  },
  rebateIntelligence: {
    mapping: {
      title: "Client Rebate Intelligence",
      rawRecordsCount: "50037",
      brandAdminCount: "49175",
      genericAdminCount: "37500",
      finalAdministrationCount: "44588",

      total: "1000000",
      totalAdministrationCount: "44700",
      totalRebatableAdminCount: "1,011,400",
      annualRebateEstimate: "$51,480,160",
      rebatePerScript: "$981",

      rebateEstimateLabel: "Rebate Estimate",
      totalRebatableRecordCountLabel: "Total Rebatable Record Count",
      excludedMedicaidEstimateLabel: "Excluded Medicaid Estimate",
      totalRebatableRecordCountValue: "26,230"
    },
    clients: [
      {
        title: "OCSRI Rebate Intelligence",
        rawRecordsCount: "26677",
        brandAdminCount: "8852",
        genericAdminCount: "17815",
        finalAdministrationCount: "2685",

        total: "1248158",
        totalAdministrationCount: "26677",
        totalRebatableAdminCount: "2,685",
        annualRebateEstimate: "$4,992,632",
        rebatePerScript: "$464",

        rebateEstimateLabel: "OCSRI Rebate Estimate",
        excludedMedicaidEstimateLabel: "Total Rebatable Admin Count",
        totalRebatableRecordCountLabel: "",
        totalRebatableRecordCountValue: "",

        powerBiLink: "https://app.powerbi.com/view?r=eyJrIjoiZDlhMTk5NTQtMzg1MC00N2NjLThhNTEtMTAxOTZhMWZlNjFlIiwidCI6ImYwZGQ3MTYzLWFjMWMtNDliMC1iYzhjLWVkMGViOGZhNDY4MSIsImMiOjN9&pageName=ReportSectionb59ec4deedc18db3796c"
      },
      {
        title: "UCS Rebate Intelligence",
        rawRecordsCount: "25191",
        brandAdminCount: "11428",
        genericAdminCount: "13763",
        finalAdministrationCount: "7008",

        total: "1017042",
        totalAdministrationCount: "7008",
        totalRebatableAdminCount: "2,417",
        annualRebateEstimate: "$4,068,168",
        rebatePerScript: "$420.78",

        rebateEstimateLabel: "UCS Rebate Estimate",
        excludedMedicaidEstimateLabel: "Total Rebatable Admin Count",
        totalRebatableRecordCountLabel: "",
        totalRebatableRecordCountValue: "",

        powerBiLink: "https://app.powerbi.com/view?r=eyJrIjoiMWEyODk1YzktNDQzNS00Zjc5LWE4MzktNTdlYzNlMDNiZDUyIiwidCI6ImYwZGQ3MTYzLWFjMWMtNDliMC1iYzhjLWVkMGViOGZhNDY4MSIsImMiOjN9&pageName=ReportSectionb59ec4deedc18db3796c"
      },
      {
        title: "CCMG Rebate Intelligence",
        rawRecordsCount: "2242",
        brandAdminCount: "1284",
        genericAdminCount: "958",
        finalAdministrationCount: "624",

        total: "62731",
        totalAdministrationCount: "2242",
        totalRebatableAdminCount: "624",
        annualRebateEstimate: "$376,386",
        rebatePerScript: "$100.53",

        rebateEstimateLabel: "CCMG Rebate Estimate",
        excludedMedicaidEstimateLabel: "Total Rebatable Admin Count",
        totalRebatableRecordCountLabel: "",
        totalRebatableRecordCountValue: "",

        powerBiLink: "https://app.powerbi.com/view?r=eyJrIjoiMjk3NmYyMmYtMTViZC00NjIwLTlmMGEtNGYyN2E5YjJkMTQwIiwidCI6ImYwZGQ3MTYzLWFjMWMtNDliMC1iYzhjLWVkMGViOGZhNDY4MSIsImMiOjN9&pageName=ReportSectionb59ec4deedc18db3796c"
      }
    ]
  }
}


const defaultValues = {
  companyName: "",
  clientName: "",
  data: animationData,
  clients: [] as {client: any, playScript: string}[],
  isRender: false
};

const LottieControl = () => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({} as any), []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
      isRender: false
    });
  };

  const prepareData = (animationData: any, mapping: any, client: any) => {
    const animationDataString = JSON.stringify(animationData);

    const keys = Object.keys(mapping);
    const clientAnimationString = keys.reduce((accumulator, currentValue) => {
      return accumulator.replaceAll(mapping[currentValue], client[currentValue])
    }, animationDataString);

    return JSON.parse(clientAnimationString);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { companyName, clientName } = formValues;

    const data = prepareData(animationData, script.welcome.mapping,{companyName: clientName});
    const clients = clientsData.map((d, i) => {
      const mapping = script.rebateIntelligence.mapping;
      const client = script.rebateIntelligence.clients[i];

      return {
        client,
        playScript: prepareData(d, mapping, client)
      };
    });
    setFormValues({
      ...formValues,
      data,
      clients: clients,
      isRender: true
    });
    console.log(formValues);
    forceUpdate();
  };

  const openPowerBi = (powerBiLink: string) => {
    window.open(powerBiLink, "_blank");
  };

  return (
      // <Viewer />
      <div>
        <div className="container-form">
          <form onSubmit={handleSubmit}>
            <Grid container alignItems="center" direction="row">
              {/*<Grid item xs={2}>*/}
              {/*  <TextField*/}
              {/*    id="name-input"*/}
              {/*    name="companyName"*/}
              {/*    label="Company Name"*/}
              {/*    type="text"*/}
              {/*    value={formValues.companyName}*/}
              {/*    onChange={handleInputChange}*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={2}>
                <TextField
                    id="age-input"
                    name="clientName"
                    label="Client Name"
                    type="text"
                    value={formValues.clientName}
                    onChange={handleInputChange}
                />
              </Grid>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </form>
        </div>
        { formValues.isRender &&
            <div>
              <LottieScrollSection height={4000} animation={formValues.data} frames={[0, 340]} />
              <LottieScrollSection height={4000} animation={formValues.data} frames={[345, 503]} />

              {formValues.clients.map(({ client, playScript }) => {
                return (
                    <div>
                      <LottieScrollSection height={4000} animation={playScript} frames={[680, 892]} />
                      <LottieScrollSection height={4000} animation={playScript} frames={[902, 1060]} />
                      <div onClick={() => openPowerBi(client.powerBiLink)}>
                        <LottieScrollSection className={"powerbi-scene"} height={4000} animation={playScript} frames={[1215, 1270]} />
                      </div>
                    </div>
                )
              })}

              {/* GOODBYE SLIDE*/}
              <LottieScrollSection height={4000} animation={formValues.data} frames={[1175, 1215]} />
              {/* GOODBYE SLIDE*/}

              {/* DATA LOADER SLIDE*/}
              <LottieScrollSection height={4000} animation={formValues.data} frames={[507, 660]} />
              {/* DATA LOADER SLIDE*/}
            </div>}
      </div>
  );
};

export default LottieControl;
